/* GroceryListTotalPrice.css */
.cart-container {
    margin-top: 20px;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 5px;
  }
  
  .cart-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .cart-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .cart-container th,
  .cart-container td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .cart-container th {
    background-color: #4caf50;
    color: white;
  }
  
  .cart-container p {
    font-size: 16px;
    margin-bottom: 5px;
  }