/* Calendar styles */
.react-calendar {
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .react-calendar__navigation {
    background-color: #f2f2f2;
    padding: 10px;
  }
  
  .react-calendar__navigation button {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: #333;
  }
  
  .react-calendar__tile {
    padding: 10px;
  }
  
  .react-calendar__tile:not(.react-calendar__tile--active):hover {
    background-color: #f2f2f2;
  }
  
  .react-calendar__tile--active {
    background-color: #698B01;
    color: white;
  }