.container {
    margin: 20px;
  }
  
  .field {
    margin-bottom: 20px;
  }
  
  .label {
    font-weight: bold;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button {
    margin-top: 10px;
  }