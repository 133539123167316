.container {
    margin: 20px;
  }
  
  .table {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }
  
  .table thead th {
    color: #363636;
    text-align: left;
  }
  
  .table td,
  .table th {
    border: 1px solid #dbdbdb;
    border-width: 0 0 1px;
    padding: 0.5em 0.75em;
    vertical-align: top;
  }
  
  .table tr:last-child td,
  .table tr:last-child th {
    border-bottom-width: 0;
  }