.recipe-nft {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
  }
  
  .recipe-nft-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .recipe-nft-header h2 {
    font-size: 24px;
    color: #333;
    margin: 0;
  }
  
  .close-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: #888;
    cursor: pointer;
  }
  
  .recipe-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .recipe-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .additional-info {
    margin-bottom: 20px;
  }
  
  .additional-info label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #555;
  }
  
  .additional-info input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .mint-nft-btn {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .mint-nft-btn:hover {
    background-color: #45a049;
  }