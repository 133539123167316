.recommendation-container {
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.ingredients-dropdown {
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
}

.checkbox {
  margin-bottom: 5px;
}

.select {
  width: 100%;
}

button {
  margin-top: 10px;
}

.recommendation {
  display: flex;
  flex-direction: column;
}

.nutritional-info {
  padding: 20px;
}

.nutrient-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.nutrient-table td {
  padding: 10px;
  border-bottom: 1px solid rgb(3, 0, 0);
}

.nutrient-label {
  font-weight: bold;
  width: 150px;
  color: rgb(7, 0, 0);
  text-align: left;
}

.nutrient-value {
  text-align: right;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #aaa;
  cursor: pointer;
}

.green-button {
  background-color: #698B01;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  margin-top: 10px;
}

.green-button svg {
  margin-right: 0.5rem;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
}

.recipe-image {
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
  border-radius: 5px;
}

.recommendation-details {
  margin-top: 1rem;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.instruction {
  margin-bottom: 0.5rem;
}

.ingredient-option {
  display: flex;
  align-items: center;
}

.ingredient-icon {
  margin-right: 0.5rem;
}