.container {
    margin: 20px;
  }
  
  .profile-info {
    display: flex;
    align-items: center;
  }
  
  .profile-picture {
    margin-right: 20px;
  }
  
  .profile-picture img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  
  .profile-details {
    flex: 1;
  }
  
  .profile-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .profile-email {
    font-size: 16px;
    color: #777;
    margin-bottom: 10px;
  }
  
  .profile-bio {
    font-size: 16px;
    line-height: 1.5;
  }