.container {
  margin: 20px;
}

.recommendation-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.recommendation-item {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-buttons .button {
  margin-left: 10px;
}

.modal-content-custom {
  width: 70%;
  height: 70%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 24px;
}