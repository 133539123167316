.container {
    margin: 20px;
  }
  
  .reward-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
  }
  
  .reward-card {
    cursor: pointer;
  }
  
  .reward-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .modal-reward-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .modal-buttons .button {
    margin-left: 10px;
  }
  
  .modal-content-custom {
    width: 70%;
    height: 70%;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .close-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 24px;
  }