.recipe-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.recipe-card {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.recipe-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
}

.modal-recipe-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-buttons .button {
  flex: 1;
  margin-right: 10px;
}

.modal-buttons .button:last-child {
  margin-right: 0;
}

.modal-content-custom {
  width: 70%;
  height: 70%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.go-back {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
}

/* Styles for buttons */
.recipe-buttons {
  display: flex;
  margin-top: 10px;
}

.grocery-list-btn {
  background-color: #698b01;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.grocery-list-btn:hover {
  background-color: #536c01;
}

.grocery-list-btn svg {
  margin-right: 8px;
}

.nutritional-info-btn {
  background-color: #f2a900;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.nutritional-info-btn:hover {
  background-color: #c88700;
}

.nutritional-info-btn svg {
  margin-right: 8px;
}