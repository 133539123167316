.pricing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
  }
  
  .pricing-title {
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .pricing-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .pricing-switch span {
    margin: 0 10px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 34px;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: gray;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px; /* Added border-radius to make the slider body rounded */
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #698b01;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #698b01;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #698b01;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .pricing-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .pricing-card {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    text-align: center;
    max-width: 300px;
  }
  
  .pricing-header {
    margin-bottom: 20px;
  }
  
  .pricing-header h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .pricing-header p {
    font-size: 16px;
    color: #777;
  }
  
  .pricing-header h3 {
    font-size: 36px;
    margin-top: 20px;
  }
  
  .pricing-features {
    margin-bottom: 20px;
  }
  
  .pricing-features ul {
    list-style-type: none;
    padding: 0;
  }
  
  .pricing-features li {
    margin-bottom: 10px;
  }
  
  .pricing-footer {
    margin-top: 20px;
  }
  
  .pricing-button {
    background-color: #698b01;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .pricing-button:hover {
    background-color: #516a01;
  }
  
  @media (max-width: 768px) {
    .pricing-title {
      font-size: 28px;
    }
  
    .pricing-card {
      max-width: 100%;
    }
  
    .pricing-header h3 {
      font-size: 28px;
    }
  }