.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.message {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-header {
  background-color: #698B01;
  color: white;
  border-radius: 6px 6px 0 0;
}

.message-body {
  padding: 20px;
  background-color: #f5f5f5;
}

.workshop-item {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.workshop-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.button {
  margin-top: 10px;
}