.grocery-list-total-price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

.box {
  width: 100%;
  max-width: 800px;
}

.recipe-info {
  margin-bottom: 1rem;
}

.image-container {
  margin-bottom: 1rem;
}

.recipe-image {
  max-width: 100%;
  height: auto;
}

.filter {
  margin-bottom: 1rem;
}

.filter .field {
  margin-bottom: 0.5rem;
}

.filter .label {
  font-weight: bold;
}

.summary {
  margin-top: 1rem;
}

.table-container {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .is-size-4-mobile {
    font-size: 1.25rem !important;
  }

  .is-size-5-mobile {
    font-size: 1rem !important;
  }

  .is-size-6-mobile {
    font-size: 0.875rem !important;
  }

  .filter .field {
    margin-bottom: 1rem;
  }

  .filter .select,
  .filter .button {
    width: 100%;
  }
}