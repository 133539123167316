.container {
    margin: 20px;
  }
  
  .referral-content {
    text-align: center;
  }
  
  .referral-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .referral-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .referral-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .referral-link input {
    width: 300px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .referral-link button {
    margin-left: 10px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #698B01;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .referral-stats {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .stat-item {
    margin: 0 20px;
    text-align: center;
  }
  
  .stat-label {
    font-size: 16px;
    font-weight: bold;
  }
  
  .stat-value {
    font-size: 24px;
    color: #698B01;
  }