.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.message {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-header {
  background-color: #698B01;
  color: white;
  border-radius: 6px 6px 0 0;
}

.message-body {
  background-color: #f5f5f5;
  padding: 20px;
}

.recipe-box {
  margin-bottom: 20px;
}

.recipe-box:last-child {
  margin-bottom: 0;
}

.comment-input {
  margin-top: 10px;
  margin-bottom: 10px;
}

ul {
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}