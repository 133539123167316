.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.message {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-header {
  background-color: #698B01;
  color: white;
  border-radius: 6px 6px 0 0;
}

.message-body {
  padding: 20px;
  background-color: #f5f5f5;
}

.label {
  margin-bottom: 5px;
  color: #363636;
}

.input,
.select {
  width: 100%;
  box-sizing: border-box;
}

.button {
  background-color: #698B01;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 15px;
  margin-top: 10px;
}

.button:hover {
  background-color: #5a7801;
}

.notification {
  border-left: 4px solid #698B01;
}

.notification h3 {
  margin-top: 0;
}

.ingredients-dropdown {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}