.grocery-list-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .grocery-list-header {
    background-color: #698b01;
    color: white;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  
  .ingredient-list {
    margin-top: 20px;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .ingredient-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .ingredient-name {
    flex: 1;
    margin-right: 10px;
  }
  
  .quantity-control {
    display: flex;
    align-items: center;
  }
  
  .quantity-control button {
    background-color: #698b01;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 5px;
  }
  
  .quantity-control button:hover {
    background-color: #536c01;
  }
  
  .quantity-count {
    margin: 0 10px;
  }
  
  .delete-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .delete-btn:hover {
    background-color: #d32f2f;
  }